import React from "react"
import Layout from "../../components/h/layout"

const HalloweenBPage = () => (
  <Layout>
    <h2>You wear these under your shorts and they cover your butt.</h2>
    <h2>You open this place up but don’t forget to close it shut.</h2>
  </Layout>
)

export default HalloweenBPage
